import { Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paytrend_tool_path } from "../../Config/config";
import { AppDispatch } from "../../Store/Types/CommonTypes";
import { api_request } from "../../Store/Action/CommonAction";
import {
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  REGISTRATION_ERROR,
  REGISTRATION_LOADING,
  REGISTRATION_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_SUCCESS,
} from "../../Store/Types/AuthTypes";
import { LoadingOutlined } from "@ant-design/icons";

const LoginModal = ({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const authLoading = useSelector((state: any) => state?.auth?.authLoading);
  const user = useSelector((state: any) => state.auth?.user);
  const navigate = useNavigate();
  const [isRegister, setIsRegister] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const otpError = useSelector((state: any) => state?.auth?.verifyOtpError);

  const otpValidationMsg = useSelector(
    (state: any) => state?.auth.verifyOtpMessage
  );
  const registrationSuccess = useSelector(
    (state: any) => state?.auth.registrationSuccess
  );

  const registrationLoading = useSelector(
    (state: any) => state?.auth.registrationLoading
  );

  const otpValidation = useSelector(
    (state: any) => state?.auth.verifyOtpSuccess
  );

  useEffect(() => {
    if (otpValidation) {
      navigate(paytrend_tool_path);
      onClose(); // Close modal on success
    }
  }, [otpValidation, navigate, onClose]);

  const handleClose = () => {
    onClose();
    navigate("/");
  };

  //eslint-disable-next-line
  useEffect(() => {
    if (user?.accessToken) {
      localStorage.setItem("accessToken", user?.accessToken);
      navigate(paytrend_tool_path);
      onClose(); // Close modal on success
    }
  }, [user, navigate, onClose]);

  // const onFinish = async (values: any) => {
  //   dispatch({ type: LOGIN_LOADING });
  //   localStorage.setItem("accessMail", values.email);
  //   try {
  //     await dispatch(
  //       api_request(
  //         method_post,
  //         "/api/auth/login",
  //         {
  //           email: "renjithcm.renju@gmail.com",
  //           password: "renjith",
  //           name: values.name,
  //           company: values.company,
  //           phone: values.phone,
  //           customer_email: values.email,
  //           freeAccessFlag: true,
  //         },
  //         LOGIN_LOADING,
  //         LOGIN_SUCCESS,
  //         LOGIN_ERROR,
  //         null
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Login failed:", error);
  //   }
  // };

  const onFinish = async (values: any) => {
    if (isVerifyingOtp) {
      const email = sessionStorage.getItem("otpEmail");
      // Handle OTP verification
      try {
        const response: any = await dispatch(
          api_request(
            "POST",
            "/api/auth/verify-otp",
            { otp: values.otp, email: email },
            VERIFY_OTP_LOADING,
            VERIFY_OTP_SUCCESS,
            VERIFY_OTP_ERROR,
            null
          )
        );
      } catch (error) {
        console.error("OTP verification failed:", error);
      }
    } else if (isRegister) {
      // Handle registration
      try {
        const response: any = await dispatch(
          api_request(
            "POST",
            "/api/auth/register",
            {
              email: values.email,
              phone: values.phone,
              password: values.password,
              name: values.name,
              company: values.company,
            },
            REGISTRATION_LOADING,
            REGISTRATION_SUCCESS,
            REGISTRATION_ERROR,
            null
          )
        );

        if (response.status === 200) {
          setIsVerifyingOtp(true);
        } else {
          console.error("Registration did not succeed:", response);
        }
        sessionStorage.setItem("otpEmail", values.email);
      } catch (error) {
        console.error("Registration failed:", error);
      }
    } else {
      // Handle login
      try {
        await dispatch(
          api_request(
            "POST",
            "/api/auth/login",
            {
              emailOrPhone: values.emailOrPhone,
              password: values.password,
            },
            LOGIN_LOADING,
            LOGIN_SUCCESS,
            LOGIN_ERROR,
            null
          )
        );
      } catch (error) {
        console.error("Login failed:", error);
      }
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      footer={null}
      maskClosable={false}
    >
      <h4>
        {isVerifyingOtp
          ? "Verify OTP"
          : isRegister
          ? "Register"
          : "Login / Register to access Paytrend tool for free"}
      </h4>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {isVerifyingOtp ? (
          <>
            <Form.Item
              name="otp"
              rules={[{ required: true, message: "Please enter the OTP" }]}
            >
              <Input placeholder="Enter OTP" className="primary-input" />
            </Form.Item>
            {otpError && <p>{otpValidationMsg}</p>}
          </>
        ) : (
          <>
            {isRegister && (
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input placeholder="Name" className="primary-input" />
              </Form.Item>
            )}
            <Form.Item
              name={isRegister ? "email" : "emailOrPhone"}
              rules={[
                { required: true, message: "Please enter your email or phone" },
              ]}
            >
              <Input
                placeholder={isRegister ? "Email" : "Email or Phone"}
                className="primary-input"
              />
            </Form.Item>
            {isRegister && (
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                  {
                    validator: (_, value) =>
                      /^\d{10}$/.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Phone number must be exactly 10 digits")
                          ),
                  },
                ]}
              >
                <Input placeholder="Phone" className="primary-input" />
              </Form.Item>
            )}
            {isRegister && (
              <Form.Item
                name="company"
                rules={[
                  { required: true, message: "Please enter your company name" },
                ]}
              >
                <Input placeholder="Company" className="primary-input" />
              </Form.Item>
            )}

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
                ...(isRegister
                  ? [
                      {
                        min: 8,
                        message: "Password must be at least 8 characters",
                      },
                    ]
                  : []),
              ]}
            >
              <Input.Password
                placeholder="Password"
                className="primary-input"
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={authLoading}>
            {authLoading ? (
              <>
                Submit <LoadingOutlined />
              </>
            ) : isVerifyingOtp ? (
              "Verify OTP"
            ) : (
              <span> Submit {registrationLoading && <LoadingOutlined />}</span>
            )}
          </Button>
        </Form.Item>
      </Form>
      {!isVerifyingOtp && (
        <p>
          {isRegister ? (
            <>
              Already have an account?{" "}
              <Button type="link" onClick={() => setIsRegister(false)}>
                Login
              </Button>
            </>
          ) : (
            <>
              Don’t have an account?{" "}
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => setIsRegister(true)}
              >
                {" "}
                Register
              </span>
            </>
          )}
        </p>
      )}
    </Modal>
  );
};

export default LoginModal;
