import React, { useEffect, useState } from "react";
import { brandColors, profileThreshold } from "../../Config/constants";

import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";
import { LoadingOutlined } from "@ant-design/icons";
import { useApplicationContext } from "../../Context/app-context";

interface DataType {
  percentile_25: number;
  median: number;
  percentile_75: number;
  min: number;
  max: number;
  average: number;
}

interface Employee {
  salary: number;
}

export const calculateMaxSalaryIndex = (data: Employee[]) => {
  // Sorting the data in descending order of salary
  const sortedData = data.slice().sort((a, b) => b.salary - a.salary);

  let maxValue = sortedData[0].salary;

  for (let i = 0; i < sortedData.length; i++) {
    let value = sortedData[i].salary;

    const count = sortedData.reduce((acc, curr) => {
      return curr.salary === value ? acc + 1 : acc;
    }, 0);

    if (count > 2) {
      maxValue = value;
      break;
    }
  }

  const arr = data.filter((item) => item.salary <= maxValue);

  return arr;
};

const SalaryPercentileChart = ({
  dataSet,
  dataSetLoading,
  title,
  location,
  experience,
}: {
  dataSet: any;
  dataSetLoading: boolean;
  title: string;
  location: string;
  experience: number;
}) => {
  const { setAverageValue, isMobile } = useApplicationContext();
  const chart2 = dataSet;

  const [data, setData] = useState<DataType>({
    percentile_25: 0,
    median: 0,
    percentile_75: 0,
    min: 0,
    max: 0,
    average: 0,
  });

  const chart2Loading = dataSetLoading;
  const calculateSalaryStats = (array: any) => {
    const sortedData = array.sort((a: any, b: any) => a.salary - b.salary);

    // Calculate indices
    const totalDataPoints = sortedData.length;
    const index_25 = Math.floor(totalDataPoints * 0.25);
    const index_50 = Math.floor(totalDataPoints * 0.5);
    const index_75 = Math.floor(totalDataPoints * 0.75);

    // Retrieve values
    const min = convertToLakhsPerAnnum(sortedData[0]?.salary);
    const percentile_25 = convertToLakhsPerAnnum(sortedData[index_25]?.salary);
    const median = convertToLakhsPerAnnum(sortedData[index_50]?.salary);
    const percentile_75 = convertToLakhsPerAnnum(sortedData[index_75]?.salary);
    const max = convertToLakhsPerAnnum(sortedData[totalDataPoints - 1]?.salary);

    // Calculate average
    let totalSalary = 0;
    sortedData.forEach((item: any) => (totalSalary += item.salary));
    const average = convertToLakhsPerAnnum(totalSalary / totalDataPoints);

    return { min, percentile_25, median, percentile_75, max, average };
  };

  useEffect(() => {
    if (chart2?.length > profileThreshold) {
      const array = calculateMaxSalaryIndex(chart2);
      const stats = calculateSalaryStats(array);
      const statsAvg = calculateSalaryStats(chart2);
      setAverageValue(statsAvg?.average);

      setData(stats);
    }
    //eslint-disable-next-line
  }, [chart2]);

  useEffect(() => {
    if (chart2Loading) {
      setData({
        percentile_25: 0,
        median: 0,
        percentile_75: 0,
        min: 0,
        max: 0,
        average: 0,
      });
    }
  }, [chart2Loading]);

  return (
    <div>
      <p className="chart-heading mb-4">{title} salary percentiles</p>
      {chart2Loading ? (
        <div className="graph-nodata-div-linear">
          <LoadingOutlined className="loader primary-color" />
        </div>
      ) : (
        <div>
          <div className="d-flex mb-3 mt-3 ">
            <div style={{ height: "100px", width: "10%" }}></div>
            <div style={{ height: "100px", width: "15%", marginRight: "2px" }}>
              <svg height="30" width="100%">
                <rect
                  width="100%"
                  height="30"
                  style={{ fill: brandColors[3] }}
                />
              </svg>
              <div className="w-100 text-start mt-2">
                <p className="strip-text-left">
                  25<sup>th</sup> {!isMobile && "Percentile"}{" "}
                </p>
                <h2 className="strip-value-left">
                  {data?.percentile_25} {isMobile && <br />}
                  LPA
                </h2>
              </div>
            </div>

            <div style={{ height: "100px", width: "50%" }}>
              <svg height="30" width="100%">
                <defs>
                  <linearGradient
                    id="custom-gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      stopColor={brandColors[1]}
                      stopOpacity="1"
                    />
                    <stop
                      offset="50%"
                      stopColor={brandColors[2]}
                      stopOpacity="1"
                    />
                    <stop
                      offset="50%"
                      stopColor={brandColors[2]}
                      stopOpacity="1"
                    />
                    <stop
                      offset="100%"
                      stopColor={brandColors[1]}
                      stopOpacity="1"
                    />
                  </linearGradient>
                </defs>
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="url(#custom-gradient)"
                />
                {/* Vertical dotted line in the middle */}
                <line
                  x1="50%"
                  y1="0"
                  x2="50%"
                  y2="100%"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeDasharray="4 4"
                />
                Sorry, your browser does not support inline SVG.
              </svg>

              <div className="w-100 d-flex justify-content-between">
                <div className="w-100 text-center mt-2">
                  <p className="strip-text">Median</p>
                  <h2 className="strip-value">
                    {data?.median} {isMobile && <br />} LPA
                  </h2>
                </div>
              </div>
            </div>
            <div style={{ height: "100px", width: "15%", marginLeft: "2px" }}>
              <svg height="30" width="100%">
                <rect
                  width="100%"
                  height="30"
                  style={{ fill: brandColors[3] }}
                />
              </svg>
              <div className="w-100 text-right mt-2">
                <p className="strip-text-right">
                  75<sup>th</sup> {!isMobile && "Percentile"}{" "}
                </p>
                <h2 className="strip-value-right">
                  {data?.percentile_75} {""} {isMobile && <br />}
                  LPA
                </h2>
              </div>
            </div>
            <div style={{ height: "100px", width: "10%" }}></div>
          </div>
          <p className="chart-summary mt-4">
            The chart displays salary percentiles for the role of{" "}
            <span className="text-primary">{title}</span> in{" "}
            <span className="text-primary">{location}</span> with{" "}
            <span className="text-primary">{experience}</span>{" "}
            {experience === 1 ? "year" : "years"} of experience. Key values
            include 25<sup>th</sup> percentile: {data?.percentile_25} LPA,
            Median: {data?.median} LPA, and 75
            <sup>th</sup> percentile: {data?.percentile_75} LPA.
          </p>
        </div>
      )}
    </div>
  );
};

export default SalaryPercentileChart;
