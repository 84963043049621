"use client";

import { useNavigate, useParams } from "react-router-dom";
import blogContent from "../../Config/blogContent.json";

import { slugify } from "../../Utils/commonHelperFunctions";
import LogoComponent from "../LogoComponent";
import { Breadcrumb } from "antd";
import { Footer } from "antd/es/layout/layout";

const BlogPage = () => {
  const { slug } = useParams(); // Retrieve the 'slug' parameter from the URL
  const navigate = useNavigate();

  const blog = blogContent.find((blog: any) => slugify(blog.main) === slug);

  if (!blog) {
    return <p>Blog not found</p>;
  }

  return (
    <>
      <div className=" border-bottom py-3">
        <div className=" d-flex justify-content-between container">
          <h1
            className="m-0 text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Paytrend
          </h1>
          <LogoComponent />
        </div>
      </div>
      <div className="container mt-3">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate("/")}>Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/blog")}>
            Blog
          </Breadcrumb.Item>
          <Breadcrumb.Item>Post</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className=" blog-page py-3 ">
        <h1 className="text-center">{blog.main}</h1>
        <div className="text-left ">
          <div className="d-flex justify-content-center  ">
            <img src={blog.mainImg} alt={blog.main} className="" width={"0%"} />
          </div>

          <p>{blog.subMain}</p>
          {blog.subContent.map((section: any, index: number) => (
            <div key={index}>
              <h3>{section.subHeading}</h3>
              <p style={{ whiteSpace: "pre-wrap" }}>{section.content}</p>
            </div>
          ))}
        </div>
        {blog?.footerFlag && (
          <footer className="text-left my-5">
            <span> {blog.footerText}</span> :{" "}
            <a href={blog.footerLink} target="_blank" rel="noopener noreferrer">
              {blog.footerLink}
            </a>
          </footer>
        )}
      </div>
    </>
  );
};

export default BlogPage;
