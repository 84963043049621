import axios, { AxiosRequestConfig } from "axios";
import { AnyAction, Dispatch } from "redux";
import { backendUrl, method_get } from "../../Config/config";
import { FORBIDDEN_ACTION, INVALID_CREDENTIALS, NO_CREDITS, UNAUTHORIZED_USER } from "../Types/AuthTypes";
import { SET_COMMON_REDUCER } from "../Types/CommonTypes";

export const api_request = (
  method: string,
  path: string,
  body: any,
  loadType: string,
  successType: string,
  errorType: string,
  accessToken: string | null
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: loadType });

    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    if (method !== method_get) {
      headers["Content-Type"] = "application/json";
    }

    if (accessToken) {
      headers["token"] = "Bearer " + accessToken;
    }

    const config: AxiosRequestConfig = {
      method: method,
      url: backendUrl + path,
      headers: headers,
      data: method === method_get ? undefined : body,
    };

    try {
      const response = (await axios(config)).data;
      console.log("🚀 ~ return ~ response:", response)


      if (response.status === 200) {
        dispatch({
          type: successType,
          payload: response.data,
          message: response.message,
          sql: response.sql,
          skill: response.skill,
        });
        return response; // Return the response for further use
      } else if (response.status === 401) {
        dispatch({ type: UNAUTHORIZED_USER, message: response.message });
      } else if (response.status === 403) {
        dispatch({ type: FORBIDDEN_ACTION, message: response.message });
      }else if (response.status === 408) {
          dispatch({ type: INVALID_CREDENTIALS, message: response.message });
        }else if(response.status === 405){
        console.log("triggerd");
        dispatch({ type: NO_CREDITS, message: response.message });}
      else {

        dispatch({ type: errorType, message: response.message });

      }
    } catch (error : any) {
      console.error(error);
      dispatch({ type: errorType , message : error.response.data.message  });
    }
  };
};

export const setCommon = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      payload: data,
      type: SET_COMMON_REDUCER,
    });
  };
};
