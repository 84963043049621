"use client";
import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Space,
  Typography,
  Drawer,
  Button,
  Dropdown,
} from "antd";
import { MenuOutlined, DownOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { home_path } from "../Config/config";
import LogoComponent from "../Components/LogoComponent";

const { Header } = Layout;
const { Title } = Typography;

const NavBar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const location = useLocation();
  const pathname = location?.pathname;

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 200; // The scroll threshold
      setScrolled(window.pageYOffset > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navItems = [
    {
      key: "home",
      label: <a href={"https://humello.com/rms"}>Home</a>,
      path: "https://humello.com/rms",
    },
    { key: "products", label: "Products", path: "#", submenu: true },
    {
      key: "contact",
      label: (
        <a
          href={"https://humello.com/#contact"}
          onClick={() => scrollToSection("contact")}
        >
          Contact
        </a>
      ),
      path: "/#contact",
    },
  ];

  const productMenu = (
    <Menu>
      <Menu.Item key="paytrend">
        <a href="/">Paytrend</a>
      </Menu.Item>
      <Menu.Item key="rms">
        <a href="https://humello.com/rms">RMS</a>
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = (path: string) => {
    navigate(path);
    setIsDrawerVisible(false);
  };

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Header className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="navContent container">
        <Space className="brand" size="small">
          <LogoComponent />
        </Space>

        {/* Desktop Menu */}
        <div className="desktopMenu">
          <Menu mode="horizontal" selectedKeys={[pathname]} className="navMenu">
            {navItems.map((item) =>
              item.submenu ? (
                <Menu.Item key={item.key}>
                  <Dropdown overlay={productMenu}>
                    <a onClick={(e) => e.preventDefault()}>{item.label}</a>
                  </Dropdown>
                </Menu.Item>
              ) : (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              )
            )}
          </Menu>
        </div>

        {/* Mobile Menu (Hamburger Icon) */}
        <div className="mobileMenu">
          <Button
            icon={<MenuOutlined />}
            onClick={() => setIsDrawerVisible(true)}
            className="hamburgerIcon"
            shape="round"
          />
        </div>
      </div>

      {/* Drawer for Mobile Menu */}
      <Drawer
        title="Menu"
        placement="left"
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
        className="drawerMenu"
      >
        <Menu
          mode="vertical"
          selectedKeys={[pathname]}
          items={navItems.map((item) => ({
            ...item,
            onClick: () => handleMenuClick(item.path),
          }))}
        />
      </Drawer>
    </Header>
  );
};

export default NavBar;
