import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Divider, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PaytrendInput from "./Input";
import PaytrendOutput from "./Output";
import {
  FETCH_INDUSTRY_ERROR,
  FETCH_INDUSTRY_LOADING,
  FETCH_INDUSTRY_SUCCESS,
  FETCH_ROLE_ERROR,
  FETCH_ROLE_LOADING,
  FETCH_ROLE_SUCCESS,
} from "../../Store/Types/SalaryDashBoardReducerTypes";
import { api_request } from "../../Store/Action/CommonAction";
import { method_get } from "../../Config/config";
import { primaryColor } from "../../Config/constants";
import { AppDispatch } from "../../Store/Types/CommonTypes";
import LoginModal from "../Auth/loginModal";

const PaytrendTool = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const authState = useSelector((state: any) => state.auth?.auth);
  const handleUnauthorizedUser = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const [loading, setLoading] = useState(true); // Initial loading state
  //eslint-disable-next-line
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [progress, setProgress] = useState(0); // Progress bar value

  const accessToken = localStorage.getItem("accessToken");
  const industriesLoading = useSelector(
    (state: any) => state.salaryDashboard.industryLoading
  );

  // useEffect(() => {
  //   const getUserData = async () => {
  //     await dispatch(
  //       api_request(
  //         method_get,
  //         "/api/auth/getUserData",
  //         null,
  //         GET_USER_DATA_LOADING,
  //         GET_USER_DATA_SUCCESS,
  //         GET_USER_DATA_ERROR,
  //         accessToken
  //       )
  //     );
  //   };

  //   getUserData();

  //   if (!userData) {
  //     handleUnauthorizedUser();
  //   }
  // }, []);

  useEffect(() => {
    if (authState === false) {
      handleUnauthorizedUser();
    }
  }, [authState]);

  useEffect(() => {
    // Fetch data on component mount

    dispatch(
      api_request(
        method_get,
        "/api/pay-pulse/industries",
        null,
        FETCH_INDUSTRY_LOADING,
        FETCH_INDUSTRY_SUCCESS,
        FETCH_INDUSTRY_ERROR,
        accessToken
      )
    );

    dispatch(
      api_request(
        method_get,
        "/api/pay-pulse/roles",
        null,
        FETCH_ROLE_LOADING,
        FETCH_ROLE_SUCCESS,
        FETCH_ROLE_ERROR,
        accessToken
      )
    );
  }, [accessToken, dispatch]);
  useEffect(() => {
    // Check if all loading variables are false
    if (industriesLoading) {
      setLoading(true); // Update loading state when all are false
      // // Calculate progress based on loading variables
      const totalLoadingVariables = 2; // Total number of loading variables
      const trueCount = 1 + (industriesLoading ? 0 : 1);
      setProgress((trueCount / totalLoadingVariables) * 100); // Update progress
    } else {
      setProgress(100);
      setLoading(false); // Update loading state when all are false
    }
  }, [industriesLoading]);

  return (
    <>
      <Helmet>
        <title>Paytrend</title>
      </Helmet>
      {loading ? (
        <div className="preloader-container container">
          <h1>Paytrend</h1>
          <Progress
            className="preloader-progress"
            percent={progress}
            showInfo={false}
            status="active"
            strokeColor={{
              "0%": primaryColor,
              "100%": primaryColor,
            }}
          />
        </div>
      ) : (
        <div className="d-lg-flex">
          <div className="col-lg-3 input-container  d-lg-block">
            <div className="logo">
              <h2
                className="my-2"
                style={{ cursor: "pointer" }}
                onClick={() => (window.location.href = "/")}
              >
                Paytrend
              </h2>
            </div>
            <Divider className="m-0" />
            <PaytrendInput setShowMobileMenu={setShowMobileMenu} />
          </div>

          <div className="col-lg-9 output-container scrollable-container p-0 container ">
            <PaytrendOutput />
          </div>
        </div>
      )}
      <LoginModal visible={isModalVisible} onClose={closeModal} />
    </>
  );
};

export default PaytrendTool;
